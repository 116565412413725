import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function FormControlLabelPlacement() {
  return (
    <FormControl component='fieldset'>
      <FormLabel component='legend'>labelPlacement</FormLabel>
      <RadioGroup row aria-label='position' name='position' defaultValue='top'>
        <FormControlLabel
          value='top'
          control={<Radio />}
          label='Top'
          labelPlacement='top'
        />
        <FormControlLabel
          value='start'
          control={<Radio />}
          label='Start'
          labelPlacement='start'
        />
        <FormControlLabel
          value='bottom'
          control={<Radio />}
          label='Bottom'
          labelPlacement='bottom'
        />
        <FormControlLabel value='end' control={<Radio />} label='End' />
      </RadioGroup>
    </FormControl>
  );
}
