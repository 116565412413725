import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const currencies = [
  {
    value: 'USD',
    label: '$',
  },
  {
    value: 'EUR',
    label: '€',
  },
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'JPY',
    label: '¥',
  },
];

export default function SelectTextFields() {
  const [currency, setCurrency] = React.useState('EUR');

  const handleChange = (event) => {
    setCurrency(event.target.value);
  };

  return (
    <Box
      component='form'
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete='off'
    >
      <div>
        <TextField
          id='outlined-select-currency'
          select
          label='Select'
          value={currency}
          onChange={handleChange}
          helperText='Please select your currency'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='outlined-select-currency-native'
          select
          label='Native select'
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText='Please select your currency'
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <div>
        <TextField
          id='filled-select-currency'
          select
          label='Select'
          value={currency}
          onChange={handleChange}
          helperText='Please select your currency'
          variant='filled'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='filled-select-currency-native'
          select
          label='Native select'
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText='Please select your currency'
          variant='filled'
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
      <div>
        <TextField
          id='standard-select-currency'
          select
          label='Select'
          value={currency}
          onChange={handleChange}
          helperText='Please select your currency'
          variant='standard'
        >
          {currencies.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id='standard-select-currency-native'
          select
          label='Native select'
          value={currency}
          onChange={handleChange}
          SelectProps={{
            native: true,
          }}
          helperText='Please select your currency'
          variant='standard'
        >
          {currencies.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </TextField>
      </div>
    </Box>
  );
}
